import React, { useState, useEffect } from "react"
import { XIcon } from '@heroicons/react/outline'
import ClearFilters from "./ClearFilters"

const MobileFilters = ({ children }) => {

  const [open, setOpen] = useState(false)

  // Set the body to overflow hidden when mobile filter is open
  useEffect(() =>{
    if( open ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible"
    }
  }, [open])

  return (
        <>
          {/* Filter Button */}
          <div 
            className={'bg-[#F5F5F5] block lg:hidden'}
            onClick={() => setOpen(true)}
            onKeyPress={() => setOpen(true)}
            role="button"
            tabIndex="0"
          >
            <div className="h-px w-11/12 mx-auto bg-[#A5A5A5] opacity-50"></div>
            <div className="font-display text-sm text-blue leading-loose text-center w-full py-4 font-bold">Filters +</div>
          </div>
        {/* Filter Popup */}
        <div className={`${open ? 'visible' : 'invisible'}`}>
            <div className={`fixed z-50 inset-0 overflow-y-auto ${open ? 'opacity-100' : 'opacity-0'} duration-300 ease-in-out transition`}>
                <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
                    <div className="flex flex-col justify-between min-h-screen w-full align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all">
                      <div>
                        <div className="bg-white py-4 shadow px-6 flex flex-row justify-between items-center">
                            <button className="text-darkgrey font-display uppercase text-xl flex items-center w-full focus:outline-none" onClick={() => setOpen(false)}>
                                <i className="fal fa-angle-left mr-2"></i>
                                Filters
                            </button>
                            <button
                                type="button"
                                className="bg-white rounded text-darkgrey focus:outline-none"
                                onClick={() => setOpen(false)}
                            >
                                <span className="sr-only">Close</span>
                                <XIcon className="h-7 w-7" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="w-full px-6 pt-6">
                            <div className="flex flex-col gap-1">
                              {children}
                            </div>
                        </div>
                      </div>
                      <div className="px-6 py-4 flex flex-row gap-2">
                        <ClearFilters className="w-1/2"/>
                        <button
                            type="button"
                            className="w-1/2 cursor-pointer inline-flex justify-center items-center px-5 py-3 line-crop font-display text-white transition duration-300 text-sm !leading-tight bg-blue mx-auto text-center focus:outline-none rounded-lg"
                            onClick={() => setOpen(false)}
                        >
                            See results
                        </button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default MobileFilters