import { createRef, default as React, useState, useMemo, useEffect, useRef } from "react";
import algoliasearch from "algoliasearch";
import { InstantSearch } from "react-instantsearch-dom";
import SearchBox from "./SearchBox";
import SearchResult from "./SearchResult";
import Pagination from "./Pagination";
import CurrentFilters from "./CurrentFilters";
import ContentTypeFilter from "./ContentTypeFilter";
import persistantOrder from "./persistantOrder";
import useClickOutside from "./useClickOutside";
import Heading from "src/components/Heading";
import MobileFilters from './MobileFilters';
import { navigate } from "gatsby";
import { BrowserView, MobileView } from 'react-device-detect';
import { Helmet } from 'react-helmet';

const Search = ({ indices, location }) => {

    const createURL = (state) => {
        const params = new URLSearchParams();

        if (state.page) params.set('page', state.page);
        if (state.hitsPerPage) params.set('hitsPerPage', state.hitsPerPage);
        if (state.query) params.set('query', state.query);

        if (state.refinementList) {
            Object.keys(state.refinementList).forEach((key) => {
                const urlKey = key === 'field_component' ? 'tag' : key;
                if (Array.isArray(state.refinementList[key])) {
                    params.set(urlKey, state.refinementList[key].join(','));
                }
            });
        }

        return `?${params.toString()}`;
    };

    const searchStateToUrl = (searchState) => searchState ? createURL(searchState) : '';

    const urlToSearchState = ({ search }) => {
        const params = new URLSearchParams(search.slice(1));
        const state = {};

        if (params.get('page')) state.page = params.get('page');
        if (params.get('hitsPerPage')) state.hitsPerPage = params.get('hitsPerPage');
        if (params.get('query')) state.query = params.get('query');

        params.forEach((value, key) => {
            const stateKey = key === 'tag' ? 'field_component' : key;
            if (stateKey !== 'page' && stateKey !== 'hitsPerPage' && stateKey !== 'query') {
                state.refinementList = state.refinementList || {};
                state.refinementList[stateKey] = value.split(',');
            }
        });

        return state;
    };

    const rootRef = createRef();
    const [hasFocus, setFocus] = useState(false);
    const DEBOUNCE_TIME = 400;
    const searchClient = useMemo(
        () =>
            algoliasearch(
                "5GVWWQ9UVM",
                "6aa4f3e7c1460e3997e2483af7ebb7da"
            ),
        []
    );
    useClickOutside(rootRef, () => setFocus(false));

    const [searchState, setSearchState] = useState(urlToSearchState(location));
    const debouncedSetStateRef = useRef(null);

    function onSearchStateChange(updatedSearchState) {
        clearTimeout(debouncedSetStateRef.current);

        debouncedSetStateRef.current = setTimeout(() => {
            navigate(searchStateToUrl(updatedSearchState));
        }, DEBOUNCE_TIME);

        setSearchState(updatedSearchState);
    }

    useEffect(() => {
        setSearchState(urlToSearchState(location));
    }, [location]);

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={indices[0].name}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
            createURL={createURL}
        >
            <Helmet>
                <title>Insights | Govox</title>
                <meta name="description" content="Explore Govox's Insights and Resources for valuable information on enhancing mental health and wellbeing in schools, workplaces, and communities. Access expert articles, case studies, and actionable strategies to improve wellbeing." />
            </Helmet>
            <div className="h-[30vh] w-full bg-blue relative flex justify-center items-center">
                <Heading size="h1" className="text-white text-center !text-4xl lg:!text-5xl px-6">Insights</Heading>
            </div>
            <div className="max-w-7xl mx-auto pb-16">
                <div className="w-full lg:-mt-10 -mt-10 relative z-100">
                    <div className="lg:max-w-6xl flex-grow mx-auto w-11/12">
                        <SearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} className="relative" />
                        {<MobileView>
                            <MobileFilters>
                                <CurrentFilters />
                                <ContentTypeFilter
                                    attribute="field_component"
                                    transformItems={items => persistantOrder(items)}
                                />
                            </MobileFilters>
                        </MobileView>
                        }
                    </div>
                </div>
                <div className="flex flex-row py-16">
                    <div className="w-1/5 px-6 hidden lg:block">
                        <Heading size="h6" className="text-blue font-extrabold">Filters</Heading>
                        {<BrowserView>
                            <CurrentFilters />
                            <ContentTypeFilter
                                attribute="field_component"
                                transformItems={items => persistantOrder(items)}
                            />
                        </BrowserView>
                        }
                    </div>
                    <div className="lg:w-4/5 w-full lg:px-0 px-6">
                        <SearchResult
                            show={searchState && searchState.length > 0 && hasFocus}
                            indices={indices}
                        />
                    </div>
                </div>
                <div>
                    <Pagination />
                </div>
            </div>
        </InstantSearch>
    );
}

export default Search;
